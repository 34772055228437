import { SVGProps } from "react";

const SvgGiLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={44}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M32.986 42.808v-7.34s-1.716 6.172-9.37 7.745c-3.366.69-11.228.2-17.207-6.184a21.462 21.462 0 0 1-4.39-6.84 22.514 22.514 0 0 1-1.534-8.276 22.319 22.319 0 0 1 1.629-8.507A20.605 20.605 0 0 1 6.58 6.591a21.01 21.01 0 0 1 6.848-4.522C16 .996 19.227.4 22.515.4c6.9 0 11.163 2.336 14.327 4.91a.605.605 0 0 1 .173.703.605.605 0 0 1-.15.21l-5.503 4.951a.607.607 0 0 1-.797.015c-1.335-1.118-3.814-2.67-7.994-2.67-7.777 0-13.421 5.633-13.421 13.39a14.6 14.6 0 0 0 .968 5.412 12.616 12.616 0 0 0 2.766 4.236 12.205 12.205 0 0 0 4.265 2.776c1.699.651 3.506.979 5.328.966 5.13 0 8.919-3.295 10.383-8.096a.609.609 0 0 1 .584-.437h8.838a.61.61 0 0 1 .429.177.6.6 0 0 1 .177.425v15.44a.596.596 0 0 1-.373.556.61.61 0 0 1-.233.046h-8.688a.61.61 0 0 1-.562-.371.596.596 0 0 1-.046-.231Z"
      fill="currentColor"
    />
    <path
      d="M38.493 22.952c3.04 0 5.506-2.444 5.506-5.458 0-3.015-2.465-5.459-5.506-5.459s-5.507 2.444-5.507 5.459 2.466 5.458 5.507 5.458Z"
      fill="currentColor"
    />
  </svg>
);
export default SvgGiLogo;
